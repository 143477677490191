<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <div>Har gitt samtykke for lagring av navn og kontaktinformasjon:</div>
                        <v-radio-group v-model="samtykke.samtykket">
                            <v-radio label="Ja" :value="true" hide-details style="margin-top: 2px"></v-radio>
                            <v-radio label="Nei" :value="false" hide-details style="margin-top: 2px"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" v-if="samtykke && samtykke.samtykket">
                        <div>Fransiskushjelpen kan kontakte meg for følgende:</div>
                        <v-checkbox v-model="samtykke.nyhetsbrev" label="Nyhetsbrev" hide-details style="margin-top: 2px"></v-checkbox>
                        <v-checkbox v-model="samtykke.arrangement" label="Invitasjoner til arrangementer" hide-details style="margin-top: 2px"></v-checkbox>
                        <v-checkbox
                            v-model="samtykke.marked"
                            label="Informasjon om hvordan gi en gave til Fransiskushjelpen"
                            hide-details
                            style="margin-top: 2px"
                        ></v-checkbox>
                        <v-checkbox v-model="samtykke.ingen" label="Ingen av alternativene" hide-details style="margin-top: 2px"></v-checkbox>
                        <v-checkbox v-model="samtykke.annen" label="Annet" hide-details style="margin-top: 2px">
                            <template v-slot:label>
                                <v-text-field
                                    v-model="samtykke.annenGrunn"
                                    placeholder="Annet"
                                    hide-details
                                    style="max-width: 300px; margin-top: -14px"
                                ></v-text-field>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <date-field v-model="samtykke.dato" label="Dato" max-width="150px" :rules="rules.dato"></date-field>
                    </v-col>
                </v-row>
            </v-form>
            <br />
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="changed" color="primary" v-on:click="save">Lagre</v-btn>
            <v-btn text v-if="changed" color="primary" v-on:click="cancel">Angre endringer</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
//import moment from 'moment';
import { mapActions } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';

export default {
    name: 'TabPrivacy',
    components: {
        DateField,
    },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        const samtykke = {
            dato: null,
            samtykket: null,
            nyhetsbrev: null,
            arrangement: null,
            marked: null,
            ingen: null,
            annen: null,
            annenGrunn: null,
        };

        return {
            readonly: false,
            changed: false,

            samtykke: samtykke,
            ingenSamtykke: Object.assign({}, samtykke),

            person: {},

            rules: {
                dato: [(v) => !!v || 'Må oppgis'],
            },
        };
    },

    watch: {
        value: async function (value) {
            this.person = this.value = value;
            this.update();
        },
        samtykke: {
            handler: function () {
                this.changed = true;
            },
            deep: true,
        },
    },

    /**
     * created
     */
    created: function () {
        if (this.value) {
            this.person = this.value;
            this.update();
        }
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson']),
        ...mapActions(['snackbar']),

        /**
         * update
         */
        update: function () {
            if (this.person.samtykke) {
                this.samtykke = this.person.samtykke;
            } else {
                this.samtykke = Object.assign({}, this.ingenSamtykke);
            }
            this.changed = false;

            window.setTimeout(() => {
                this.changed = false;
                this.$refs.form.resetValidation();
            }, 100);
        },

        /**
         * save
         */
        save: async function () {
            if (!this.$refs.form.validate()) {
                console.log('TODO: error message');
            } else {
                try {
                    let person = null;

                    const data = Object.assign({}, this.samtykke);
                    if (data.dato) {
                        data.dato = data.dato.format('DD.MM.YYYY');
                    }
                    if (!data.samtykket) {
                        data.nyhetsbrev = null;
                        data.arrangement = null;
                        data.marked = null;
                        data.ingen = null;
                        data.annen = null;
                        data.annenGrunn = null;
                    }

                    const response = await this.request({
                        method: 'post',
                        url: '/person/' + this.person.id + '/samtykke',
                        data: data,
                    });

                    if (response && typeof response.id != 'undefined') {
                        person = response;
                    }

                    // success
                    if (person) {
                        this.person = await this.formatPerson(person);
                        this.update();

                        this.$emit('input', this.person);

                        this.snackbar('Lagret');
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },

        /**
         * cancel
         */
        cancel: function () {
            this.update();
        },
    },
};
</script>
<style lang="scss"></style>
